body {
  background: #0f0326;
}

.App {
  font-family: sans-serif;
  display: flex;

  color: lightgray;
}

h2 {
  margin: 0;
}

.ads {
  width:290rem;
}

.content {
  flex: 1 0 20%;
}

.year {
  background: gray;
  border: solid black 1px;
  border-radius: 3px;
  padding: 2px;
  margin: 5px;
}

.yearNumber {
  color: white;
  text-align: center;
}

.semester-container {
  display: flex;
  color: black;
}

.semester {
  background: lightgray;
  border: solid black 1px;
  flex-grow: 1;
  width: 35%;
}

.semester.summer {
  width: 30%;
}

.semester.settings {
  width: 70%;
}

.semesterLabel {
  background: white;
  text-align: left;
  font-weight: bold;
  white-space: nowrap;
}

ul {
  list-style-type: none;
  padding-inline-start: 0.25em;
  margin-block-start: 0.25em;
}

.course {
  color: white;
  border: solid black 1px;
  border-radius: 5px;
  width: 10rem;
  flex-direction: row;
  padding: 10px;
  margin: 2px;
  font-size: 1.25rem;
}

.semester .course {
  width: auto;
}

.course.ISYE {
  background: #2f6095;
}
.course.CSE,
.course.CS {
  background: #d2483e;
}

.course.MGT,
.course.PUBP {
  background: #7a9c54;
}

.semester-course-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100% - 32px);
}

.course-container {
  display: flex;
  flex-direction: row;
}

.schedule {
  flex: 1 1 0;
}

.couse-master-list {
  flex: 1 1 0;
  justify-content: flex-start;
}

.couse-master-list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.trash {
  font-size: 4rem;
}

.semester-header {
  display: flex;
  background: white;
  padding: 5px;
  text-align: left;
  justify-content: space-between;
}

.drag-hovered {
  background: rgba(255, 255, 255, 0.7);
  border: dashed gray 5px;
  height: calc(100% - 42px);
}

.tracks {
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-items: flex-start;
}

.tracks-container .title {
  font-weight: bold;
  padding-bottom: 0.3em;
  text-align: flex-start;
}

.tracks-container {
  display: flex;
  justify-content: center;

  text-overflow: ellipsis;
  padding: 10px;
  padding-left: 1em;
}

.tracks-container li {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fullName {
  font-weight: bold;
}

.description {
  font-size: .9rem;
}

.requirement-container {
}

.requirement-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1em;
}

.requirement-list li {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.additional-config {
  display: flex;
  opacity: 1;
  height: 6rem;
  transition: height 0.3s ease-out, opacity 0.2s, visibility 0.2s;
  visibility: visible;
}

.additional-config.hide {
  opacity: 0;
  height: 0; /*figure out a transitionf orhtis*/
  margin-block-start: 0rem;
  visibility: hidden;
}

.suggestion-config {
  display: flex;
  opacity: 1;
  max-height: 15rem;
  transition: max-height 0.3s ease-out, opacity 0.2s, visibility 0.2s;
  visibility: visible;
}

.suggestion-config.hide {
  opacity: 0;
  max-height: 0; /*figure out a transitionf orhtis*/
  margin-block-start: 0rem;
  visibility: hidden;
}

.right-side{
  flex: 0 0 0;
  height : 100%;
  background: #0f0326;
}


.left-side {
  flex: 1 1 0;
  height : 100%;
  background: #0f0326;
}

.hide-screenshot.hide{
  display:none;
}

.requirement-holder {
  display: flex;
  justify-content: left;
}

.heading h2 {
  display: inline-block;
  margin: 5px;
}


.couse-master-list .filters {
  display: grid;
  grid-template-columns: 8rem [col-start]9rem [col-start]9rem [col-start] 11rem [col-start];

  text-align: left;
}

.couse-master-list .filters-holder {
  display: flex;
  justify-content: center;
  padding: 0.3rem;
}

.content .header {
  display: flex;
  justify-content: space-between;
}

.header button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}


.screenshot-holder {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    pointer-events: none;
}

.screenshot-holder img{
  pointer-events: auto;
}

.screenshot-header{
    color: red;
    font-size: 2em;
    text-align: center;
    font-family: system-ui;
}


.button-holder {
  display: flex;
  align-items: center;
}

.modal {

    display: flex;
    align-items: center;
    justify-content : center;
    color: lightgray;
    

}

a {
  color: #0075ff;
}